// Placeholder to allow overriding predefined variables smoothly.
$background-color: #0d1117;
$dm-text-color: #e6edf3;
$lm-brand-color: #7d8590;
$subtitle-bk-color: #161b22;
$link-base-color: #2f81f7;
$link-visited-color: #2f81f7;
$border-color-01: #30363d;
$border-color-02: #465059;
$sidebar-background-color: #010409;
$code-background-color: #3b3b3b;